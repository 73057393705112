.contact-page {
 padding: 2rem 0;

 .contact-info {
  h2 {
   font-size: 2rem;
   margin-bottom: 1rem;
  }

  p {
   margin-bottom: 1rem;
  }
 }

 .contact-form {
  form {
   display: flex;
   flex-direction: column;

   .form-group {
    margin-bottom: 1rem;

    input,
    textarea {
     width: 100%;
     padding: 0.5rem;
     border: 1px solid #ccc;
     border-radius: 0.25rem;
    }

    textarea {
     resize: vertical;
    }
   }

   .send-btn {
    background-color: #f0ad4e; // Use color of your choice
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
     background-color: darken($color: #f0ad4e, $amount: 15%);
    }
   }
  }
 }


}