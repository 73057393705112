// .navbar {
//  background-color: #fff;

// }

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Wendy+One&display=swap');
.navbar-brand {
 display: flex;
 align-items: center;
 font-size: 1.5rem;
 font-family: 'Inria Sans', sans-serif;
 font-weight: 600;



 color: #333;

}

.navbar-brand .editor {
 font-weight: 200;
 font-size: .8rem;
 margin-left: .5rem;


}

.navbar-brand .dot {
 height: 20px;

 width: 20px;

 background-color: #FFC107;

 border-radius: 50%;
 display: inline-block;
 margin-right: 0.5rem;

}

.separator {
 display: inline-block;
 margin: 0 10px;

 transform: scaleY(2);
 font-weight: 200;

}

.know-me {
 font-weight: 600;
 font-size: 1.5rem;
 font-family: 'Inria Sans', sans-serif;
 margin-left: .2rem;

}

.hello {
 font-size: 5rem;
 font-family: 'Inria Sans', sans-serif;

}





.button-group-1 {
 position: relative;
 font-size: 1rem;
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 10rem;
}

.btn-portfolio,
.btn-contact {
 padding: 10px 20px;
 font-family: 'Inria Sans', sans-serif;
 font-weight: 400;
 text-align: center;
 position: absolute;
 white-space: nowrap;
 background-color: red;
}


.btn-contact:hover {
 background-color: rgb(7, 142, 142);
}

.btn-portfolio:hover {
 background-color: rgb(197, 129, 3);
}

.btn-portfolio {
 background-color: #dc9635;

 border-radius: 50%;
 z-index: 2;
 left: calc(30% - 20px);
 border: 3px solid #fff;
 width: 10rem;
 height: 10rem;


}

.btn-contact {
 background-color: #17a2b8;
 height: 8rem;
 width: 8rem;
 border-radius: 50%;
 left: 50%;
 border: 3px solid #fff;
 z-index: 1;
 align-items: center;


}

.font-s {
 font-family: "Quicksand", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}


