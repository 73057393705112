@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700);



@import url('https://fonts.googleapis.com/css?family=Caveat|Righteous&display=swap');

.wrapper {
 background: black;
}

.loader-main {
 background: black;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
}

.loader-text {
 font-family: 'Righteous', cursive;
 background: url('https://media.giphy.com/media/FE0WTM8BG754I/giphy.gif') center center no-repeat;
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 font-size: 10vw; // Responsive font size
 letter-spacing: 2px; // Adjusted for smaller screens
 background-size: cover;
 margin: 0px;
 color: transparent;

 @media (min-width: 992px) {
  // For larger devices
  font-size: 7rem; // Original size
  letter-spacing: 10px;
 }
}
