// Import Bootstrap functions, variables, and mixins
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.footer-border {
 border-top: 1px solid #9e929253; // Adjust the color and thickness as needed
}

.footer {
 margin-top: 8rem;
 bottom: 0;
 width: 100%;
 line-height: 1rem; // Adjust the height of the footer
 // Your footer background color

 .container {
  max-width: 1140px;
 }

 h5 {
  font-size: .9rem;
  margin-bottom: 0.5rem;
 }

 p {
  margin-bottom: 0;
  font-size: .9rem;
 }

 .fa-github,
 .fa-linkedin {
  font-size: 1.2rem;
 }

 a {
  color: red;

  &:hover {
   color: greenyellow;
  }
 }

 // Customize your breakpoints if needed
 @include media-breakpoint-up(sm) {
  .p-3 {
   padding: 1rem !important;
  }
 }

 @include media-breakpoint-up(md) {
  .p-4 {
   padding: 1.5rem !important;
  }
 }

 @include media-breakpoint-up(lg) {
  .p-5 {
   padding: 3rem !important;
  }
 }
}