@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200&display=swap');
$color-primary: rgb(143, 143, 143);
$color-bright: rgb(218, 218, 218);

.nav-wrapper {
 display: flex;
 flex-direction: column;
 justify-content: flex-end;
 width: 100vw;
 height: 100vh;
 background-image: linear-gradient(90deg, rgba(7, 6, 33, 0.82) 0%, rgba(16, 16, 51, 0.862) 35%, rgba(5, 34, 39, 0.83) 100%), url(../imgs/bg.png);
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center;
 background-size: 150%;
 position: fixed;
 padding-bottom: 5vh;
 transition: all 1s cubic-bezier(.55, .085, 0, .99);
 transition-timing-function: cubic-bezier(.55, .085, 0, .99);
 transition-delay: 100ms;
 z-index: 4000;
 font-family: 'Titillium Web', sans-serif;


}

.nav-btn {
 top: 3%;
 right: 3%;
 position: absolute;
 width: 2.5rem;
 height: 2.5rem;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius: 50%;
 cursor: pointer;
 color: $color-primary;
 z-index: 5000;
}

.nav-btn:hover {
 color: $color-bright;
}

.navbar {
 width: 100%;
 height: 80%;

 &-list {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 2rem;
 }

 &-link {
  text-decoration: none;
 }

 &-item {
  list-style: none;
  color: $color-primary;
  font-size: 2rem;
  cursor: pointer;

  &:hover {
   color: $color-bright;
  }
 }
}

.social {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 row-gap: 1rem;


 &-text {
  text-align: center;
  font-size: 1rem;
  color: $color-primary;
  font-weight: 200;
 }

 &-list {
  display: flex;
  justify-content: center;
  column-gap: .7rem;
  width: 30%;
  padding-left: 0;
 }

 &-icon {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  color: $color-primary;
  border: solid $color-primary;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
   color: $color-bright;
   border: solid $color-bright;
  }

 }
}

.hide-nav {
 transform: translateY(-100%);
}